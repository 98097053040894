import { Box, TextField, Grid, Paper, Typography , styled , Button } from '@mui/material'
import React from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation }       from 'react-i18next';
import { useState, useEffect }  from "react";
import Loading                  from "../../Loading";
import { useLectures }    from '../../../hooks/useLectures';
const Image = styled("img")({
    width: "300px",
});

export default function HomeLecture() {
    const navigate                  = useNavigate();
    const lang                      = Cookies.get("i18next") || "en";
    const [searchInput, setSearchInput] = React.useState("");
    const {t}                       = useTranslation();
    const {data , isLoading}        = useLectures();
    const [Lectures, setLectures]   = useState([]);
    useEffect(() => {
        if (data?.data) {

            setLectures(data.data);
        }
      }, [data]);
    
    return (
        <>
        <Box sx={{padding:"32px 24px",marginY:"30px"}}>
          <Typography sx={{fontSize:{md:"26px",xs:"22px"},fontWeight:"700",color:"#151313",textAlign:"center",
                marginBottom:"50px"}}>{t('home_lectures')}</Typography>
        
        <TextField
                sx={{ m: 1, width: "90%" }}
                label={t("search")}
                variant="outlined"
                value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            />

        {   Lectures?.length > 0 &&
            Lectures?.map((item,index) =>
            {
                return(
                <>
                <Grid  item  xs={12} md={4}  lg={3} sx={{ marginTop:"20px" , display: "inline-block", width : "30%" , marginRight:"20px"}} key={item.id + "kmk"}>
                    <Paper
                        sx={{
                        padding: "0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor:"#f1f1f1",
                        borderRadius:"20px"
                        }}
                    >
                        <Image
                        alt={lang==="ar"?item?.titleAR:item?.titleEN}
                        src={`${process.env.REACT_APP_API_KEY}images/${item?.image}`}
                        sx={{ width: "100%", height: "200px", objectFit:"cover" , borderRadius:"20px"}}
                        />
                        <Typography
                        sx={{
                            width:"100%",
                            display:"block",
                            fontWeight: "500",
                            marginY:    "10px",
                            fontSize:   "16px",
                            minHeight:  "auto",
                            padding:"10px !important",
                            margin:"0px 10px",
                            textAlign:  "left !important",
                            color:"#212121"
                        }}
                        >
                        {lang==="ar"?item?.titleAR:item?.titleEN}
                        <br />

                        </Typography>
                        <p style={{width:"90%", fontSize: "14px" , color:"#888" , textAlign:  "left !important"}}>
                        {lang==="ar"?item?.descriptionAr:item?.descriptionEn}

                        </p>
                        
                        <a className='btndetails'
                        onClick={() => navigate(`/course/${item.id}`)}
                        >
                        {t("view")}
                        </a>
                    </Paper>
                </Grid>
                </>
            )}
        )}
            
        
        </Box>
        </>
    )
}
