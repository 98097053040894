import { Box, TextField, Grid, Paper, Typography , styled , Button } from '@mui/material'
import React from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation }       from 'react-i18next';
import { useState, useEffect }  from "react";
import Loading                  from "../../Loading";
import { useTeachers }    from '../../../hooks/useTeachers';
import countries              from "../../../data/countries";
const Image = styled("img")({
    width: "300px",
});

export default function HomePackage() {
    const navigate                  = useNavigate();
    const lang                      = Cookies.get("i18next") || "en";
    const [searchInput, setSearchInput] = React.useState("");
    const {t}                       = useTranslation();
    const {data , isLoading}        = useTeachers();
    const [Teachers, setTeachers]   = useState([]);
    
    useEffect(() => {
        if (data?.data) {
            
            setTeachers(data.data);
        }
      }, [data]);
    
    return (
        <Box sx={{padding:"32px 24px",marginY:"30px"}}>
          <Typography sx={{fontSize:{md:"26px",xs:"22px"},fontWeight:"700",color:"#151313",textAlign:"center",
                marginBottom:"50px"}}>{t('home_teachers')}</Typography>

        {   Teachers?.length > 0 &&
            Teachers?.map((item,index) =>
            {
                
                let c;
                c = countries.find((e) => e.code == item?.country);
                return(
                <>
                { ( item.Rates.length > 0 ) ?
                <Grid  item  xs={12} md={4}  lg={3} sx={{ marginTop:"20px" , display: "inline-block", width : "30%" , marginRight:"20px"}} key={item.id + "kmk"}>
                    <Paper
                        sx={{
                        padding: "0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor:"#f1f1f1",
                        borderRadius:"20px"
                        }}
                    >
                        <div style={{width:"100px", height: "100px" , border: "2px solid rgb(255, 255, 255);" , boxShaow: "rgb(0, 91, 142) -2px 2px;" ,
                            borderRadius:"50%",
                            marginBottom:"20px",
                            marginTop:"20px",
                        }}>
                        <Image
                        alt={lang==="ar"?item?.titleAR:item?.firstName}
                        src={`${process.env.REACT_APP_API_KEY}images/${item?.image}`}
                        sx={{ width: "100%", height: "100%", objectFit:"cover" , borderRadius:"50%", borderBottom:"1px solid #CCC"}}
                        />
                        </div>
                        <h2
                        style={{
                            width:"90%",
                            display:"block",
                            fontWeight: "500",
                            marginY:    "10px",
                            fontSize:   "16px",
                            minHeight:  "auto",
                            padding:"10px !important",
                            margin:"0px 10px",
                            textAlign:  "center",
                            color:"#212121",
                            marginBottom:"10px"
                        }}
                        >
                        {item?.firstName} {item?.lastName} 

                        </h2>
                        <div style={{width:"100%"}}>
                        {item?.TeacherLevels.length > 0 ?
                            <p style={{width:"30%", fontSize: "12px" , fontWeight:"500", color:"#888", padding:"5px 10px" , backgroundColor : "#f8f8f8" , borderRadius:"15px" , display:"inline-block" , textAlign:"center" , margin:"5px"}}>
                            {lang==="ar"? item?.TeacherLevels[0]?.Level?.titleAR:item?.TeacherLevels?.Level?.titleEN} 
                            </p>
                        : ""}

                        {item?.TeacherLimits.length > 0 ?
                            <p style={{width:"40%", fontSize: "12px" , fontWeight:"500" , color:"#888", padding:"5px 10px" , backgroundColor : "#f8f8f8" , borderRadius:"15px" , display:"inline-block" , textAlign:"center" , margin:"5px"}}>
                            {lang==="ar"? item?.TeacherLimits[0]?.LimeType?.titleAR : item?.TeacherLimits?.LimeType?.titleEN} 
                            </p>
                        : ""}

                        {item?.TeacherTypes.length > 0 ?
                                <p style={{width:"40%", fontSize: "12px", fontWeight:"500" , color:"#888", padding:"5px 10px" , backgroundColor : "#f8f8f8" , borderRadius:"15px" , display:"inline-block", textAlign:"center" , margin:"5px"}}>
                                {lang==="ar"? item?.TeacherTypes[0]?.TrainingCategoryType.titleAR:item?.TeacherTypes[0]?.TrainingCategoryType.titleEN} 
                                </p>
                        : ""}

                        {item?.CurriculumTeachers.length > 0 ?
                            <p style={{width:"40%", fontSize: "12px", fontWeight:"500" , color:"#888", padding:"5px 10px" , backgroundColor : "#f8f8f8" , borderRadius:"15px" , display:"inline-block", textAlign:"center" , margin:"5px"}}>
                                {lang==="ar"? item?.CurriculumTeachers[0]?.Curriculum.titleAR:item?.CurriculumTeachers[0]?.Curriculum.titleEN} 
                            </p>
                        : ""}

                        {
                            <p style={{width:"40%", fontSize: "12px", fontWeight:"500" , color:"#888", padding:"5px 10px" , backgroundColor : "#f8f8f8" , borderRadius:"15px" , display:"inline-block", textAlign:"center" , margin:"5px"}}>
                                {lang==="ar"? c?.name_en : c?.name_ar} 
                            </p>
                        }

                        </div>
                        
                        <Button
                        style={{marginBottom:"20px",
                            backgroundColor:"#800000",
                            color:"#FFF",
                            borderRadius:"15px",
                            marginTop:"20px",
                            width:"90%",
                            fontWeight:"500",
                            fontSize:"12px"
                        }}
                        onClick={() => navigate(`/teacher/${item.id}`)}
                        >
                        {t("contactTeacher")}
                        </Button>
                    </Paper>
                </Grid>
                : ""
                }
                </>
            )}
        )}
            
        
        </Box>
    )
}
