export default [
  { name_en: "Afghanistan", code: "AF", name_ar: "أفغانستان" },
  { name_en: "Albania", code: "AL", name_ar: "ألبانيا" },
  { name_en: "Algeria", code: "DZ", name_ar: "الجزائر" },
  { name_en: "Andorra", code: "AD", name_ar: "أندورا" },
  { name_en: "Angola", code: "AO", name_ar: "أنغولا" },
  { name_en: "Antigua and Barbuda", code: "AG", name_ar: "أنتيغوا وباربودا" },
  { name_en: "Argentina", code: "AR", name_ar: "الأرجنتين" },
  { name_en: "Armenia", code: "AM", name_ar: "أرمينيا" },
  { name_en: "Australia", code: "AU", name_ar: "أستراليا" },
  { name_en: "Austria", code: "AT", name_ar: "النمسا" },
  { name_en: "Azerbaijan", code: "AZ", name_ar: "أذربيجان" },
  { name_en: "Bahamas", code: "BS", name_ar: "جزر البهاما" },
  { name_en: "Bahrain", code: "BH", name_ar: "البحرين" },
  { name_en: "Bangladesh", code: "BD", name_ar: "بنغلاديش" },
  { name_en: "Barbados", code: "BB", name_ar: "باربادوس" },
  { name_en: "Belarus", code: "BY", name_ar: "بيلاروس" },
  { name_en: "Belgium", code: "BE", name_ar: "بلجيكا" },
  { name_en: "Belize", code: "BZ", name_ar: "بليز" },
  { name_en: "Benin", code: "BJ", name_ar: "بنين" },
  { name_en: "Bhutan", code: "BT", name_ar: "بوتان" },
  { name_en: "Bolivia", code: "BO", name_ar: "بوليفيا" },
  { name_en: "Bosnia and Herzegovina", code: "BA", name_ar: "البوسنة والهرسك" },
  { name_en: "Botswana", code: "BW", name_ar: "بوتسوانا" },
  { name_en: "Brazil", code: "BR", name_ar: "البرازيل" },
  { name_en: "Brunei", code: "BN", name_ar: "بروناي" },
  { name_en: "Bulgaria", code: "BG", name_ar: "بلغاريا" },
  { name_en: "Burkina Faso", code: "BF", name_ar: "بوركينا فاسو" },
  { name_en: "Burundi", code: "BI", name_ar: "بوروندي" },
  { name_en: "Cabo Verde", code: "CV", name_ar: "الرأس الأخضر" },
  { name_en: "Cambodia", code: "KH", name_ar: "كمبوديا" },
  { name_en: "Cameroon", code: "CM", name_ar: "الكاميرون" },
  { name_en: "Canada", code: "CA", name_ar: "كندا" },
  {
    name_en: "Central African Republic",
    code: "CF",
    name_ar: "جمهورية أفريقيا الوسطى",
  },
  { name_en: "Chad", code: "TD", name_ar: "تشاد" },
  { name_en: "Chile", code: "CL", name_ar: "تشيلي" },
  { name_en: "China", code: "CN", name_ar: "الصين" },
  { name_en: "Colombia", code: "CO", name_ar: "كولومبيا" },
  { name_en: "Comoros", code: "KM", name_ar: "جزر القمر" },
  { name_en: "Congo (Congo-Brazzaville)", code: "CG", name_ar: "الكونغو" },
  { name_en: "Costa Rica", code: "CR", name_ar: "كوستاريكا" },
  { name_en: "Croatia", code: "HR", name_ar: "كرواتيا" },
  { name_en: "Cuba", code: "CU", name_ar: "كوبا" },
  { name_en: "Cyprus", code: "CY", name_ar: "قبرص" },
  { name_en: "Czechia (Czech Republic)", code: "CZ", name_ar: "التشيك" },
  {
    name_en: "Democratic Republic of the Congo",
    code: "CD",
    name_ar: "جمهورية الكونغو الديمقراطية",
  },
  { name_en: "Denmark", code: "DK", name_ar: "الدنمارك" },
  { name_en: "Djibouti", code: "DJ", name_ar: "جيبوتي" },
  { name_en: "Dominica", code: "DM", name_ar: "دومينيكا" },
  { name_en: "Dominican Republic", code: "DO", name_ar: "جمهورية الدومنيكان" },
  { name_en: "Ecuador", code: "EC", name_ar: "الإكوادور" },
  { name_en: "Egypt", code: "EG", name_ar: "مصر" },
  { name_en: "El Salvador", code: "SV", name_ar: "السلفادور" },
  { name_en: "Equatorial Guinea", code: "GQ", name_ar: "غينيا الاستوائية" },
  { name_en: "Eritrea", code: "ER", name_ar: "إريتريا" },
  { name_en: "Estonia", code: "EE", name_ar: "إستونيا" },
  { name_en: "Eswatini (fmr. 'Swaziland')", code: "SZ", name_ar: "إسواتيني" },
  { name_en: "Ethiopia", code: "ET", name_ar: "إثيوبيا" },
  { name_en: "Fiji", code: "FJ", name_ar: "فيجي" },
  { name_en: "Finland", code: "FI", name_ar: "فنلندا" },
  { name_en: "France", code: "FR", name_ar: "فرنسا" },
  { name_en: "Gabon", code: "GA", name_ar: "الغابون" },
  { name_en: "Gambia", code: "GM", name_ar: "غامبيا" },
  { name_en: "Georgia", code: "GE", name_ar: "جورجيا" },
  { name_en: "Germany", code: "DE", name_ar: "ألمانيا" },
  { name_en: "Ghana", code: "GH", name_ar: "غانا" },
  { name_en: "Greece", code: "GR", name_ar: "اليونان" },
  { name_en: "Grenada", code: "GD", name_ar: "غرينادا" },
  { name_en: "Guatemala", code: "GT", name_ar: "غواتيمالا" },
  { name_en: "Guinea", code: "GN", name_ar: "غينيا" },
  { name_en: "Guinea-Bissau", code: "GW", name_ar: "غينيا بيساو" },
  { name_en: "Guyana", code: "GY", name_ar: "غيانا" },
  { name_en: "Haiti", code: "HT", name_ar: "هايتي" },
  { name_en: "Honduras", code: "HN", name_ar: "هندوراس" },
  { name_en: "Hungary", code: "HU", name_ar: "المجر" },
  { name_en: "Iceland", code: "IS", name_ar: "آيسلندا" },
  { name_en: "India", code: "IN", name_ar: "الهند" },
  { name_en: "Indonesia", code: "ID", name_ar: "إندونيسيا" },
  { name_en: "Iran", code: "IR", name_ar: "إيران" },
  { name_en: "Iraq", code: "IQ", name_ar: "العراق" },
  { name_en: "Ireland", code: "IE", name_ar: "أيرلندا" },
  { name_en: "Israel", code: "IL", name_ar: "إسرائيل" },
  { name_en: "Italy", code: "IT", name_ar: "إيطاليا" },
  { name_en: "Jamaica", code: "JM", name_ar: "جامايكا" },
  { name_en: "Japan", code: "JP", name_ar: "اليابان" },
  { name_en: "Jordan", code: "JO", name_ar: "الأردن" },
  { name_en: "Kazakhstan", code: "KZ", name_ar: "كازاخستان" },
  { name_en: "Kenya", code: "KE", name_ar: "كينيا" },
  { name_en: "Kiribati", code: "KI", name_ar: "كيريباس" },
  { name_en: "Kuwait", code: "KW", name_ar: "الكويت" },
  { name_en: "Kyrgyzstan", code: "KG", name_ar: "قيرغيزستان" },
  { name_en: "Laos", code: "LA", name_ar: "لاوس" },
  { name_en: "Latvia", code: "LV", name_ar: "لاتفيا" },
  { name_en: "Lebanon", code: "LB", name_ar: "لبنان" },
  { name_en: "Lesotho", code: "LS", name_ar: "ليسوتو" },
  { name_en: "Liberia", code: "LR", name_ar: "ليبيريا" },
  { name_en: "Libya", code: "LY", name_ar: "ليبيا" },
  { name_en: "Liechtenstein", code: "LI", name_ar: "ليختنشتاين" },
  { name_en: "Lithuania", code: "LT", name_ar: "ليتوانيا" },
  { name_en: "Luxembourg", code: "LU", name_ar: "لوكسمبورغ" },
  { name_en: "Madagascar", code: "MG", name_ar: "مدغشقر" },
  { name_en: "Malawi", code: "MW", name_ar: "مالاوي" },
  { name_en: "Malaysia", code: "MY", name_ar: "ماليزيا" },
  { name_en: "Maldives", code: "MV", name_ar: "المالديف" },
  { name_en: "Mali", code: "ML", name_ar: "مالي" },
  { name_en: "Malta", code: "MT", name_ar: "مالطا" },
  { name_en: "Marshall Islands", code: "MH", name_ar: "جزر مارشال" },
  { name_en: "Mauritania", code: "MR", name_ar: "موريتانيا" },
  { name_en: "Mauritius", code: "MU", name_ar: "موريشيوس" },
  { name_en: "Mexico", code: "MX", name_ar: "المكسيك" },
  { name_en: "Micronesia", code: "FM", name_ar: "ميكرونيزيا" },
  { name_en: "Moldova", code: "MD", name_ar: "مولدوفا" },
  { name_en: "Monaco", code: "MC", name_ar: "موناكو" },
  { name_en: "Mongolia", code: "MN", name_ar: "منغوليا" },
  { name_en: "Montenegro", code: "ME", name_ar: "الجبل الأسود" },
  { name_en: "Morocco", code: "MA", name_ar: "المغرب" },
  { name_en: "Mozambique", code: "MZ", name_ar: "موزمبيق" },
  { name_en: "Myanmar (formerly Burma)", code: "MM", name_ar: "ميانمار" },
  { name_en: "Namibia", code: "NA", name_ar: "ناميبيا" },
  { name_en: "Nauru", code: "NR", name_ar: "ناورو" },
  { name_en: "Nepal", code: "NP", name_ar: "نيبال" },
  { name_en: "Netherlands", code: "NL", name_ar: "هولندا" },
  { name_en: "New Zealand", code: "NZ", name_ar: "نيوزيلندا" },
  { name_en: "Nicaragua", code: "NI", name_ar: "نيكاراغوا" },
  { name_en: "Niger", code: "NE", name_ar: "النيجر" },
  { name_en: "Nigeria", code: "NG", name_ar: "نيجيريا" },
  { name_en: "North Korea", code: "KP", name_ar: "كوريا الشمالية" },
  { name_en: "North Macedonia", code: "MK", name_ar: "مقدونيا الشمالية" },
  { name_en: "Norway", code: "NO", name_ar: "النرويج" },
  { name_en: "Oman", code: "OM", name_ar: "عمان" },
  { name_en: "Pakistan", code: "PK", name_ar: "باكستان" },
  { name_en: "Palau", code: "PW", name_ar: "بالاو" },
  { name_en: "Palestine State", code: "PS", name_ar: "فلسطين" },
  { name_en: "Panama", code: "PA", name_ar: "بنما" },
  { name_en: "Papua New Guinea", code: "PG", name_ar: "بابوا غينيا الجديدة" },
  { name_en: "Paraguay", code: "PY", name_ar: "باراغواي" },
  { name_en: "Peru", code: "PE", name_ar: "بيرو" },
  { name_en: "Philippines", code: "PH", name_ar: "الفلبين" },
  { name_en: "Poland", code: "PL", name_ar: "بولندا" },
  { name_en: "Portugal", code: "PT", name_ar: "البرتغال" },
  { name_en: "Qatar", code: "QA", name_ar: "قطر" },
  { name_en: "Romania", code: "RO", name_ar: "رومانيا" },
  { name_en: "Russia", code: "RU", name_ar: "روسيا" },
  { name_en: "Rwanda", code: "RW", name_ar: "رواندا" },
  { name_en: "Saint Kitts and Nevis", code: "KN", name_ar: "سانت كيتس ونيفيس" },
  { name_en: "Saint Lucia", code: "LC", name_ar: "سانت لوسيا" },
  {
    name_en: "Saint Vincent and the Grenadines",
    code: "VC",
    name_ar: "سانت فنسنت وجزر غرينادين",
  },
  { name_en: "Samoa", code: "WS", name_ar: "ساموا" },
  { name_en: "San Marino", code: "SM", name_ar: "سان مارينو" },
  {
    name_en: "Sao Tome and Principe",
    code: "ST",
    name_ar: "ساو تومي وبرينسيب",
  },
  { name_en: "Saudi Arabia", code: "SA", name_ar: "المملكة العربية السعودية" },
  { name_en: "Senegal", code: "SN", name_ar: "السنغال" },
  { name_en: "Serbia", code: "RS", name_ar: "صربيا" },
  { name_en: "Seychelles", code: "SC", name_ar: "سيشيل" },
  { name_en: "Sierra Leone", code: "SL", name_ar: "سيراليون" },
  { name_en: "Singapore", code: "SG", name_ar: "سنغافورة" },
  { name_en: "Slovakia", code: "SK", name_ar: "سلوفاكيا" },
  { name_en: "Slovenia", code: "SI", name_ar: "سلوفينيا" },
  { name_en: "Solomon Islands", code: "SB", name_ar: "جزر سليمان" },
  { name_en: "Somalia", code: "SO", name_ar: "الصومال" },
  { name_en: "South Africa", code: "ZA", name_ar: "جنوب أفريقيا" },
  { name_en: "South Korea", code: "KR", name_ar: "كوريا الجنوبية" },
  { name_en: "South Sudan", code: "SS", name_ar: "جنوب السودان" },
  { name_en: "Spain", code: "ES", name_ar: "إسبانيا" },
  { name_en: "Sri Lanka", code: "LK", name_ar: "سريلانكا" },
  { name_en: "Sudan", code: "SD", name_ar: "السودان" },
  { name_en: "Suriname", code: "SR", name_ar: "سورينام" },
  { name_en: "Sweden", code: "SE", name_ar: "السويد" },
  { name_en: "Switzerland", code: "CH", name_ar: "سويسرا" },
  { name_en: "Syria", code: "SY", name_ar: "سوريا" },
  { name_en: "Taiwan", code: "TW", name_ar: "تايوان" },
  { name_en: "Tajikistan", code: "TJ", name_ar: "طاجيكستان" },
  { name_en: "Tanzania", code: "TZ", name_ar: "تنزانيا" },
  { name_en: "Thailand", code: "TH", name_ar: "تايلاند" },
  { name_en: "Timor-Leste", code: "TL", name_ar: "تيمور الشرقية" },
  { name_en: "Togo", code: "TG", name_ar: "توغو" },
  { name_en: "Tonga", code: "TO", name_ar: "تونغا" },
  { name_en: "Trinidad and Tobago", code: "TT", name_ar: "ترينيداد وتوباغو" },
  { name_en: "Tunisia", code: "TN", name_ar: "تونس" },
  { name_en: "Turkey", code: "TR", name_ar: "تركيا" },
  { name_en: "Turkmenistan", code: "TM", name_ar: "تركمانستان" },
  { name_en: "Tuvalu", code: "TV", name_ar: "توفالو" },
  { name_en: "Uganda", code: "UG", name_ar: "أوغندا" },
  { name_en: "Ukraine", code: "UA", name_ar: "أوكرانيا" },
  {
    name_en: "United Arab Emirates",
    code: "AE",
    name_ar: "الإمارات العربية المتحدة",
  },
  { name_en: "United Kingdom", code: "GB", name_ar: "المملكة المتحدة" },
  {
    name_en: "United States of America",
    code: "US",
    name_ar: "الولايات المتحدة الأمريكية",
  },
  { name_en: "Uruguay", code: "UY", name_ar: "أوروغواي" },
  { name_en: "Uzbekistan", code: "UZ", name_ar: "أوزبكستان" },
  { name_en: "Vanuatu", code: "VU", name_ar: "فانواتو" },
  { name_en: "Vatican City", code: "VA", name_ar: "مدينة الفاتيكان" },
  { name_en: "Venezuela", code: "VE", name_ar: "فنزويلا" },
  { name_en: "Vietnam", code: "VN", name_ar: "فيتنام" },
  { name_en: "Yemen", code: "YE", name_ar: "اليمن" },
  { name_en: "Zambia", code: "ZM", name_ar: "زامبيا" },
  { name_en: "Zimbabwe", code: "ZW", name_ar: "زيمبابوي" },
];
