import React , { useEffect, useState }from "react";
import Navbar from "../../components/Navbar";
import {  Box,  Container,  Paper,  Tab,  Tabs, Typography , styled } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useStudent } from "../../hooks/useStudent";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
const Image = styled('img')({
  width:"500px"
})

export default function StudentProfile() {
  const { id } = useParams();
  const { data, isLoading } = useStudent(id);
  const [ objStudent, setStudent ] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      console.log("Data of Student");
      console.log(data);
      const user = data?.data;
      setStudent(user);
      console.log(objStudent.name);
    }
  }, [data]);
  

  return (
    <Navbar>
      <Container sx={{ marginTop: "120px" }}>
        <Typography
          sx={{
            color: "#000000",
            fontWeight: "700",
            fontSize: "24px",
            marginBottom: "8px",
          }}
        >
          {t("parent_welcome")}
        </Typography>
        <Typography
          sx={{
            color: "#000000",
            fontWeight: "500",
            fontSize: "18px",
            marginBottom: "32px",
          }}
        >
          {t("parent_desc")}
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Link to={"/parent"}>
            <Tabs value="1">
              <Tab label={t("view_data_student")} value="1" />
            </Tabs>
          </Link>
        </Box>
        <Container>
          {!isLoading ? (
            <Paper sx={{ marginY: "20px", padding: "40px 20px 20px" }}>
              <table className="table_student">
                <tr>
                  <td>{t("p_image")}</td>
                  <td> 
                    <Image
                      src={`${process.env.REACT_APP_API_KEY}images/${objStudent?.image}`}
                      className="img-profile"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{t("p_student_name")}</td>
                  <td> {objStudent?.name}</td>
                </tr>

                <tr>
                  <td>{t("p_student_phone")}</td>
                  <td> {objStudent?.phoneNumber}</td>
                </tr>

                
                <tr>
                  <td>{t("p_student_gender")}</td>
                  <td> {objStudent?.gender == "1" ? "ذكر" : "انثي"}</td>
                </tr>

                <tr>
                  <td>{t("p_student_regionTime")}</td>
                  <td> {objStudent?.regionTime }</td>
                </tr>

                

                <tr>
                  <td>{t("p_student_city")}</td>
                  <td> {objStudent?.city}</td>
                </tr>
                <tr>
                  <td>{t("p_student_email")}</td>
                  <td> {objStudent?.email}</td>
                </tr>
                <tr>
                  <td>{t("p_student_nationality")}</td>
                  <td> {objStudent?.nationality}</td>
                </tr>

                <tr>
                  <td>{t("p_student_dateOfBirthl")}</td>
                  <td> {objStudent?.dateOfBirth}</td>
                </tr>

                <tr>
                  <td>{t("p_student_location")}</td>
                  <td> {objStudent?.location}</td>
                </tr>

              </table>
              
            </Paper>
          ) : (
            <Loading />
          )}
        </Container>
      </Container>
    </Navbar>
  );
}
