import {useQuery} from 'react-query'

async function getCareer(id)
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/admin/career/${id}`)
    return response.json()
}

export const useCareer = (id) =>
{
    return useQuery('get-careers',getCareer(id))
}