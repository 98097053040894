import { Box, TextField, Grid, Paper, Typography , styled , Button } from '@mui/material'
import React from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation }       from 'react-i18next';
import { useState, useEffect }  from "react";
import Loading                  from "../../Loading";
import currencies                    from "../../../data/currencies";
import { usePackagesAccept }    from '../../../hooks/usePackageAccept';
const Image = styled("img")({
    width: "300px",
});

export default function HomePackage() {
    const navigate                  = useNavigate();
    const lang                      = Cookies.get("i18next") || "en";
    const [searchInput, setSearchInput] = React.useState("");
    const {t}                       = useTranslation();
    const {data , isLoading}        = usePackagesAccept();
    const [Packages, setPackages]   = useState([]);
    useEffect(() => {
        if (data?.data) {
            console.log(data);

            setPackages(data.data);
        }
      }, [data]);
    
    

    return (
        <Box sx={{padding:"32px 24px",marginY:"30px"}}>
          <Typography sx={{fontSize:{md:"26px",xs:"22px"},fontWeight:"700",color:"#151313",textAlign:"center",
                marginBottom:"50px"}}>{t('home_package')}</Typography>
        
        <TextField
                sx={{ m: 1, width: "90%" }}
                label={t("search")}
                variant="outlined"
                value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            />

        {   Packages?.length > 0 &&
            Packages?.map((item,index) =>
            {
                let current_currency;
                current_currency = currencies.find((e) => e.title == item?.currency);
                console.log(item);

                let gender_txt = "";
                if(item?.gender == "male"){
                    gender_txt = t("male") ;
                  }else if(item?.gender == "female"){
                    gender_txt = t("female") ;
                  }else{
                    gender_txt = t("male&female") ;
                  }

                return(
                <>
                <Grid  item  xs={12} md={4}  lg={3} sx={{ marginTop:"20px" , display: "inline-block", width : "30%" , marginRight:"20px"}} key={item.id + "kmk"}>
                <Paper
                        sx={{
                        padding: "0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor:"#f1f1f1",
                        borderRadius:"20px"
                        }}
                    >
                        <Image
                        alt={lang==="ar"?item?.titleAR:item?.titleEN}
                        src={`${process.env.REACT_APP_API_KEY}images/${item?.image}`}
                        sx={{ width: "100%", height: "200px", objectFit:"cover" , borderRadius:"20px"}}
                        />
                        <h2 className='h2_title'>
                        {lang==="ar"?item?.titleAR:item?.titleEN}
                        </h2>
                        <p style={{width:"90%", fontSize: "14px" , color:"#888" , textAlign:  "left !important"}}>
                        {lang==="ar"?item?.descriptionAr:item?.descriptionEn}

                        </p>
                        <ul style={{width:"75%", margin:"auto" , margin:"10px", display : "block"}}>
                        <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_price')}</b> {item.price}</li>
                            <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_numTotalLesson')}</b> {item.numTotalLesson}</li>
                            <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_numWeekLesson')}</b> {item.numWeekLesson}</li>
                            <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_gender')}</b> {
                                gender_txt}</li>
                            <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_duration')}</b> {item.duration}</li>
                            <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_trainingtype')}</b> {lang==="ar"?item?.TrainingCategoryType?.titleAR:item?.TrainingCategoryType?.titleEN} </li>
                            <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_limittype')}</b> {lang==="ar"?item?.LimeType?.titleAR:item?.LimeType?.titleEN} </li>
                            <li style={{fontWeight:"400" , fontSize:"13px"}}><b style={{fontWeight:"400" , fontSize:"13px" , color:"#888" }}> {t('package_level')}</b> {lang==="ar"?item?.Level?.titleAR:item?.Level?.titleEN} </li>
                        </ul>
                        <h4 className='h4_price'>{item?.price} { ( lang==="ar" ) ?current_currency?.titleAr:current_currency?.titleEn}</h4>
                        <a className='btndetails'
                        onClick={() => navigate(`/package/${item.id}`)}
                        >
                        {t("view")}
                        </a>
                    </Paper>
                </Grid>
                </>
            )}
        )}
            
        
        </Box>
    )
}
